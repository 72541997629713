import dynamic from 'next/dynamic'
import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useMatchMedia } from '@utils/useMatchMedia'
import useFormatMessage from '@utils/useFormatMessage'
import useLanguageUrl from '@utils/useLanguageUrl'
import $amplitude from '@utils/amplitude'

import Close from '@icons/closeThin.svg'

import Gradient from './Gradient/Gradient'

import styles from './SmallBanner.module.scss'

const CountdownTimer = dynamic(
  () => import('../CountdownTimer/CountdownTimer'),
  { ssr: false }
)

const BlackFridaySmallBanner = ({
  isBlackFridayTime,
  isBFBannerClosed,
  endBFDate,
  setIsBFBannerClosed,
}) => {
  const { isMobile, isMobileButNotTablet, isTablet } = useMatchMedia()

  const t = useFormatMessage()
  const langUrlSignin = useLanguageUrl()

  const handleGoToSignIn = () =>
    $amplitude('[Landing] Get Black Friday Discount', {
      placement_type: 'small_banner',
    })

  const handleHideBanner = () => {
    sessionStorage.setItem('isBFBannerClosed', true)
    setIsBFBannerClosed(true)
  }

  // поднимаем иконку Intercom и WhatsApp выше
  useEffect(() => {
    if (isBlackFridayTime && !sessionStorage.getItem('isBFBannerClosed')) {
      const style = document.createElement('style')
      // отступ для разных разрешений
      const offset = isMobileButNotTablet ? 360 : isTablet ? 210 : 130
      const chatOffset = isMobileButNotTablet ? 420 : isTablet ? 270 : 190
  
      style.textContent = `
        .intercom-lightweight-app-launcher { bottom: ${offset}px; }
        .intercom-app div:nth-of-type(2) { bottom: ${offset}px; }
        .intercom-messenger-frame { bottom: ${chatOffset}px !important; }
        #__EAAPS_PORTAL button { bottom: ${offset}px; }
      `
  
      document.documentElement.appendChild(style)
  
      return () => {
        style.remove()
      }
    }
  }, [isBlackFridayTime, isMobile, isMobileButNotTablet, isTablet])

  return isBlackFridayTime && !isBFBannerClosed ? (
    <div className={styles.root}>
      <Gradient />
      <button className={styles.closeButton} onClick={handleHideBanner}>
        <Close />
      </button>
      <div className={styles.content}>
        <div>
          <h3 className={styles.title}>{t('blackFridayBanner.title')}</h3>
          <p className={styles.description}>
            {t('blackFridayBanner.description')}
          </p>
        </div>
        <div className={styles.timer}>
          <CountdownTimer
            className={styles.timer}
            targetDate={new Date(endBFDate).getTime()}
          />
          <a
            className={styles.link}
            href={langUrlSignin}
            rel="nofollow"
            onClick={handleGoToSignIn}
          >
            <span>{t('blackFridayBanner.button')}</span>
          </a>
        </div>
      </div>
      <picture className={styles.fenekImg}>
        <source
          type="image/webp"
          src="/images/black-friday/fenek3DFlash.webp"
        />
        <source type="image/png" src="/images/black-friday/fenek3DFlash.png" />
        <img
          src="/images/black-friday/fenek3DFlash.png"
          alt={t('blackFridayBanner.tiitle')}
        />
      </picture>
    </div>
  ) : null
}

BlackFridaySmallBanner.propTypes = {
  isBlackFridayTime: PropTypes.bool,
  isBFBannerClosed: PropTypes.bool,
  endBFDate: PropTypes.any,
  setIsBFBannerClosed: PropTypes.func,
}

export default BlackFridaySmallBanner
