import { useEffect, useState } from 'react'

const startBFDate = new Date('November 25, 2024 00:00:00 GMT+0300')
const endBFDate = new Date('December 1, 2024 23:59:59 GMT+0300')

const getUtcTime = () => {
  const now = new Date()
  return now.getTime()
};

const useBlackFridayTime = () => {
  const [isBlackFridayTime, setIsBlackFridayTime] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMounted(true)
    }
  }, [])

  useEffect(() => {
    if (isMounted) {
      const nowUtc = getUtcTime()

      const isBFTime =
        nowUtc <= endBFDate.getTime() && nowUtc >= startBFDate.getTime()

      setIsBlackFridayTime(isBFTime || sessionStorage.getItem('showTestBlackFridayBanners'))
    }
  }, [isMounted])

  return { startBFDate, endBFDate, isBlackFridayTime, isMounted }
}

export default useBlackFridayTime
