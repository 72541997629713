import styles from './Gradient.module.scss'

const Gradient = () => (
  <div className={styles.root}>
    <svg className={styles.superLarge} width="1920" height="100" viewBox="0 0 1920 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_837_4337)">
        <g opacity="0.5" filter="url(#filter0_f_837_4337)">
          <path d="M-497.543 1067.9C-702.006 1212.62 -558.128 677.283 -605.283 638.907C-1219.64 299.814 -124.958 -110.199 153.287 85.2967C431.533 280.793 1493.16 -99.8358 1242.35 120.254C962.133 366.141 1094.36 247.686 1093.04 575.795C1092.14 801.025 682.483 784.91 563.756 551.437C479.81 386.36 383.68 519.462 401.356 638.605C444.104 926.734 89.2823 572.648 -57.4643 361.745C-204.211 150.843 -241.964 886.984 -497.543 1067.9Z" fill="url(#paint0_linear_837_4337)" />
        </g>
      </g>
      <defs>
        <filter id="filter0_f_837_4337" x="-967.697" y="-143.407" width="2426.12" height="1414.11" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="89.023" result="effect1_foregroundBlur_837_4337" />
        </filter>
        <linearGradient id="paint0_linear_837_4337" x1="-589.105" y1="830.732" x2="1032.14" y2="1029.03" gradientUnits="userSpaceOnUse">
          <stop stopColor="#6792FF" />
          <stop offset="0.479166" stopColor="#C3CBD1" />
          <stop offset="1" stopColor="#FDB0FF" />
        </linearGradient>
        <clipPath id="clip0_837_4337">
          <rect width="1920" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <svg className={styles.large} width="1440" height="100" viewBox="0 0 1440 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_845_14945)">
        <g opacity="0.5" filter="url(#filter0_f_845_14945)">
          <path d="M-268.475 874.033C-430.011 988.377 -316.34 565.429 -353.595 535.11C-838.971 267.209 25.8869 -56.7234 245.716 97.7286C465.544 252.181 1304.29 -48.5359 1106.13 125.347C884.746 319.61 989.212 226.024 988.172 485.248C987.458 663.192 663.808 650.46 570.008 466.004C503.686 335.585 427.738 440.742 441.703 534.871C475.476 762.508 195.148 482.762 79.2108 316.138C-36.7267 149.513 -66.5535 731.104 -268.475 874.033Z" fill="url(#paint0_linear_845_14945)" />
        </g>
      </g>
      <defs>
        <filter id="filter0_f_845_14945" x="-677.302" y="-120.34" width="1991.52" height="1191.98" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="89.023" result="effect1_foregroundBlur_845_14945" />
        </filter>
        <linearGradient id="paint0_linear_845_14945" x1="-340.813" y1="686.661" x2="940.054" y2="843.328" gradientUnits="userSpaceOnUse">
          <stop stopColor="#6792FF" />
          <stop offset="0.479166" stopColor="#C3CBD1" />
          <stop offset="1" stopColor="#FDB0FF" />
        </linearGradient>
        <clipPath id="clip0_845_14945">
          <rect width="1920" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <svg className={styles.tablet} width="768" height="200" viewBox="0 0 768 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_837_4823)">
        <g opacity="0.5" filter="url(#filter0_f_837_4823)">
          <path d="M-893.543 1175.87C-1098.01 1320.59 -954.128 785.254 -1001.28 746.878C-1615.64 407.784 -520.958 -2.22886 -242.713 193.267C35.5327 388.763 1097.16 8.13437 846.346 228.224C566.133 474.111 698.359 355.656 697.043 683.765C696.139 908.995 286.483 892.88 167.756 659.407C83.8102 494.331 -12.3204 627.432 5.35617 746.575C48.1042 1034.7 -306.718 680.618 -453.464 469.716C-600.211 258.813 -637.964 994.955 -893.543 1175.87Z" fill="url(#paint0_linear_837_4823)" />
        </g>
      </g>
      <defs>
        <filter id="filter0_f_837_4823" x="-1363.7" y="-35.4369" width="2426.12" height="1414.11" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="89.023" result="effect1_foregroundBlur_837_4823" />
        </filter>
        <linearGradient id="paint0_linear_837_4823" x1="-985.105" y1="938.702" x2="636.138" y2="1137" gradientUnits="userSpaceOnUse">
          <stop stopColor="#6792FF" />
          <stop offset="0.479166" stopColor="#C3CBD1" />
          <stop offset="1" stopColor="#FDB0FF" />
        </linearGradient>
        <clipPath id="clip0_837_4823">
          <rect width="768" height="200" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <svg className={styles.desktop} width="1024" height="188" viewBox="0 0 1024 188" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_837_4659)">
        <g opacity="0.5" filter="url(#filter0_f_837_4659)">
          <path d="M-893.543 1169.87C-1098.01 1314.59 -954.128 779.254 -1001.28 740.878C-1615.64 401.784 -520.958 -8.22886 -242.713 187.267C35.5327 382.763 1097.16 2.13437 846.346 222.224C566.133 468.111 698.359 349.656 697.043 677.765C696.139 902.995 286.483 886.88 167.756 653.407C83.8102 488.331 -12.3204 621.432 5.35617 740.575C48.1042 1028.7 -306.718 674.618 -453.464 463.716C-600.211 252.813 -637.964 988.955 -893.543 1169.87Z" fill="url(#paint0_linear_837_4659)" />
        </g>
      </g>
      <defs>
        <filter id="filter0_f_837_4659" x="-1363.7" y="-41.4369" width="2426.12" height="1414.11" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="89.023" result="effect1_foregroundBlur_837_4659" />
        </filter>
        <linearGradient id="paint0_linear_837_4659" x1="-985.105" y1="932.702" x2="636.138" y2="1131" gradientUnits="userSpaceOnUse">
          <stop stopColor="#6792FF" />
          <stop offset="0.479166" stopColor="#C3CBD1" />
          <stop offset="1" stopColor="#FDB0FF" />
        </linearGradient>
        <clipPath id="clip0_837_4659">
          <rect width="1024" height="188" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <svg className={styles.mobile} width="375" height="332" viewBox="0 0 375 332" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_966_54751)">
        <g opacity="0.5" filter="url(#filter0_f_966_54751)">
          <path d="M-1657.54 1039.87C-1862.01 1184.59 -1718.13 649.254 -1765.28 610.878C-2379.64 271.784 -1284.96 -138.229 -1006.71 57.2669C-728.467 252.763 333.161 -127.866 82.3461 92.2241C-197.867 338.111 -65.6406 219.656 -66.9571 547.765C-67.8609 772.995 -477.517 756.88 -596.244 523.407C-680.19 358.331 -776.32 491.432 -758.644 610.575C-715.896 898.704 -1070.72 544.618 -1217.46 333.716C-1364.21 122.813 -1401.96 858.955 -1657.54 1039.87Z" fill="url(#paint0_linear_966_54751)" />
        </g>
      </g>
      <defs>
        <filter id="filter0_f_966_54751" x="-2127.7" y="-171.437" width="2426.12" height="1414.11" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="89.023" result="effect1_foregroundBlur_966_54751" />
        </filter>
        <linearGradient id="paint0_linear_966_54751" x1="-1749.1" y1="802.702" x2="-127.862" y2="1001" gradientUnits="userSpaceOnUse">
          <stop stopColor="#6792FF" />
          <stop offset="0.479166" stopColor="#C3CBD1" />
          <stop offset="1" stopColor="#FDB0FF" />
        </linearGradient>
        <clipPath id="clip0_966_54751">
          <rect width="375" height="332" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
)

export default Gradient