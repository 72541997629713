import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import useBlackFridayTime from '@utils/useBlackFridayTime'

import BlackFridaySmallBanner from '@components/blackFriday/SmallBanner/SmallBanner'

import Cookies from '../coockies/coockies'

export default function Banners() {
  const [isBFBannerClosed, setIsBFBannerClosed] = useState(true)

  const { route } = useRouter()

  const isMainPage = route === '/'
  const { isBlackFridayTime, endBFDate } = useBlackFridayTime()

  useEffect(() => {
    const isBFBannerClosed = sessionStorage.getItem('isBFBannerClosed')
    setIsBFBannerClosed(JSON.parse(isBFBannerClosed))
  }, [])

  return (
    <>
      {!isMainPage &&
        <BlackFridaySmallBanner
          isBlackFridayTime={isBlackFridayTime}
          isBFBannerClosed={isBFBannerClosed}
          endBFDate={endBFDate}
          setIsBFBannerClosed={setIsBFBannerClosed}
        />
      }
      <Cookies
        isBlackFridayTime={isBlackFridayTime}
        isBFBannerClosed={isBFBannerClosed}
        isMainPage={isMainPage}
      />
    </>
  )
}
