import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import cns from 'classnames'

import {
  footerDataLinksProduct,
  footerDataLinksAbout,
  footerDataLinksHelpful,
} from '@data/footerData'

import { CURRENT_YEAR } from '@constants/date'

import $amplitude from '@utils/amplitude'
import useFormatMessage from '@utils/useFormatMessage'
import useBlackFridayTime from '@utils/useBlackFridayTime'

import SocialLinks from '@components/socialLinks/socialLinks'
import FacebookMsg from '@components/facebookMsg/facebookMsg'
import Breadcrumbs from '@components/layouts/breadcrumbs/breadcrumbs'
import FooterList from '@components/layouts/footer/footerList'

import styles from './footer.module.scss'
import Banners from './banners/banners'

export default function Footer({ pageId, breadcrumbsList = null }) {
  const t = useFormatMessage()
  const { locale, route } = useRouter()
  const classModifier = pageId
  const isBR = locale === 'br'
  const { isBlackFridayTime } = useBlackFridayTime()

  const handleFooterLinkClick = item => () => {
    $amplitude('[Landing] Footer Click', {
      page_name: pageId,
      item,
    })
  }

  const organizationSchema = {
    '@context': 'https://schema.org/',
    '@type': 'Organization',
    image: 'https://livedune.com/apple-touch-icon.png',
    url: '//livedune.com/',
    email: 'support@livedune.com',
    name: 'ООО «Дюна»',
    telephone: ['+7 (933) 399-75-26', '+7 (933) 399-75-61'],
  }

  return (
    <>
      <footer
        className={cns(
          styles.footer,
          classModifier && styles[`footer_${classModifier}`],
          isBlackFridayTime && route !== '/' && styles.extraPadding
        )}
      >
        <div className={styles.footer__wrapper}>
          <div className={`wrapper`}>
            {pageId === 'autoposting' ? null : (
              <Breadcrumbs list={breadcrumbsList} />
            )}

            <div
              className={cns(
                styles.footer__content,
                classModifier && styles[`footer__content_${classModifier}`]
              )}
            >
              <div className={styles.footer__container}>
                <div className={styles.footer__logoWrapper}>
                  <img
                    className={styles.footer__logo}
                    src="/images/logo-flat.svg"
                    alt={t(`footer.alts.accountStats`)}
                  />
                </div>

                <div className={styles.contacts}>
                  {!isBR && (
                    <a
                      className={cns(
                        styles.footer__text,
                        classModifier &&
                          styles[`footer__text_${classModifier}`],
                        styles.footer__text_email,
                        classModifier &&
                          styles[`footer__text_email_${classModifier}`],
                        styles.footer__link,
                        classModifier && styles[`footer__link_${classModifier}`]
                      )}
                      href={'mailto:support@livedune.com'}
                      onClick={handleFooterLinkClick('support@livedune.com')}
                    >
                      support@livedune.com
                    </a>
                  )}
                </div>

                {locale === 'ru' && (
                  <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                      __html: JSON.stringify(organizationSchema),
                    }}
                  />
                )}

                <div className={styles.footerInfo}>
                  {isBR && (
                    <div className={styles.footer__text}>
                      {t('footer.texts.br.contacts')}
                    </div>
                  )}

                  <div
                    className={cns(
                      styles.footer__text,
                      classModifier && styles[`footer__text_${classModifier}`],
                      styles.footer__text_pr,
                      classModifier &&
                        styles[`footer__text_pr_${classModifier}`],
                      isBR && styles.footer__text_lh
                    )}
                  >
                    {t(
                      isBR
                        ? 'footer.texts.address'
                        : 'footer.texts.prDepartment'
                    )}
                    {isBR ? '' : ':'}
                  </div>

                  {!isBR && (
                    <ul
                      className={cns(
                        styles.footer__list,
                        classModifier &&
                          styles[`footer__list_${classModifier}`],
                        styles.footer__list,
                        classModifier && styles[`footer__list_${classModifier}`]
                      )}
                    >
                      <li>
                        <a
                          className={cns(
                            styles.footer__text,
                            classModifier &&
                              styles[`footer__text_${classModifier}`],
                            styles.footer__text,
                            classModifier &&
                              styles[`footer__text_${classModifier}`],
                            styles.footer__link,
                            classModifier &&
                              styles[`footer__link_${classModifier}`]
                          )}
                          href="mailto:pr@livedune.com"
                          onClick={handleFooterLinkClick('pr@livedune.com')}
                        >
                          pr@livedune.com
                        </a>
                      </li>
                    </ul>
                  )}

                  <div
                    className={cns(
                      styles.footer__text,
                      !isBR && styles.footer__text_light,
                      isBR && styles.footer__text_cnpj
                    )}
                    dangerouslySetInnerHTML={{
                      __html: t(
                        isBR
                          ? 'footer.texts.cnpj'
                          : 'footer.texts.cooperationQuestions'
                      ),
                    }}
                  />
                  {isBR && (
                    <div className={styles.footer__text_mail}>
                      <span className={styles.footer__text}>
                        {t('footer.texts.br.email')}
                      </span>
                      <a
                        className={cns(
                          styles.footer__text,
                          classModifier &&
                            styles[`footer__text_${classModifier}`],
                          styles.footer__text_emailBr,
                          classModifier &&
                            styles[`footer__text_email_${classModifier}`],
                          styles.footer__link,
                          classModifier &&
                            styles[`footer__link_${classModifier}`]
                        )}
                        href={'mailto:experience@livedune.com'}
                        onClick={handleFooterLinkClick(
                          'experience@livedune.com'
                        )}
                      >
                        experience@livedune.com
                      </a>
                    </div>
                  )}
                </div>
              </div>

              <div
                className={cns(
                  styles.footer__section,
                  classModifier && styles[`footer__section_${classModifier}`]
                )}
              >
                <div
                  className={cns(
                    styles.footer__box,
                    classModifier && styles[`footer__box_${classModifier}`]
                  )}
                >
                  <FooterList
                    pageId={pageId}
                    id="product"
                    listItems={footerDataLinksProduct}
                  />
                </div>

                <div
                  className={cns(
                    styles.footer__box,
                    classModifier && styles[`footer__box_${classModifier}`]
                  )}
                >
                  <FooterList
                    pageId={pageId}
                    id="about"
                    listItems={footerDataLinksAbout}
                  />
                </div>

                <div
                  className={cns(
                    styles.footer__box,
                    classModifier && styles[`footer__box_${classModifier}`]
                  )}
                >
                  <FooterList
                    pageId={pageId}
                    id="helpful"
                    listItems={footerDataLinksHelpful}
                  />
                </div>
              </div>
            </div>

            <div className={styles.footer__bottom}>
              {['ru', 'kz'].includes(locale) && (
                <div className={styles.footer__social}>
                  <SocialLinks />
                </div>
              )}
              <p
                className={cns(
                  styles.footer__copyright,
                  classModifier && styles[`footer__copyright_${classModifier}`]
                )}
              >
                {t(`footer.texts.Copyright`, {
                  date: CURRENT_YEAR,
                })}
              </p>
            </div>
          </div>
        </div>

        {locale === 'ru' && <FacebookMsg pageId={pageId} />}
      </footer>

      <Banners />
    </>
  )
}

Footer.propTypes = {
  pageId: PropTypes.string,
  breadcrumbsList: PropTypes.array,
}
